.overlay {
  &__header{
    color: red;
    height: 100vh;
    width: 100vw;
    margin-top: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}