.splash__content {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash__image {
  animation: spin 4s linear infinite;
  margin-bottom: 3.5rem;
}

@keyframes spin {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}